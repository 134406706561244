<script setup>
const props = defineProps({
  to: {
    type: String || null,
    default: null,
  },
  size: {
    type: String || null,
    default: null,
  },
  defaultColor: {
    type: String,
    default: "primary",
  },
  secondColor: {
    type: String || null,
    default: null,
  },
  textColor: {
    type: String,
    default: "white",
  },
  cursor: {
    type: String,
    default: "pointer",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["r:click"]);

const { to, size, textColor, defaultColor, secondColor, cursor, disabled } =
  toRefs(props);

const router = useRouter();

const button = ref(null);

const _secondColor = computed(() => {
  if (secondColor.value) {
    return secondColor.value;
  }
  switch (defaultColor.value) {
    case "primary":
      return "cherry";
    case "dark-gray":
    case "gray-dark":
      return "wax";
    case "nude":
      return "mediator";
    default:
      return "cherry";
  }
});

watch(textColor, (newValue, prevValue) => {
  button.value.classList.remove(`text-${prevValue}`);
  button.value.classList.add(`text-${newValue}`);
});

watch(defaultColor, (newValue, prevValue) => {
  // bg-nude
  button.value.classList.remove(`bg-${prevValue}`);
  button.value.classList.add(`bg-${newValue}`);
});

watch(_secondColor, (newValue, prevValue) => {
  button.value.classList.remove(`hover:bg-${prevValue}`);
  button.value.classList.add(`hover:bg-${newValue}`);
});

onMounted(() => {
  if (disabled.value === true && defaultColor.value === "primary") {
    button.value.classList.add("bg-cherry");
    button.value.classList.add("bg-opacity-20");
    button.value.classList.add("text-white");
    button.value.classList.add("cursor-default");
  } else {
    button.value.classList.add(`bg-${defaultColor.value}`);
    button.value.classList.add(`hover:bg-${_secondColor.value}`);
    button.value.classList.add(`text-${textColor.value}`);
    switch (size.value) {
      case "sm":
        button.value.classList.add(`py-3/2`);
        button.value.classList.add(`px-5`);
        break;
      case "lg":
        button.value.classList.add(`py-10p`);
        button.value.classList.add(`px-10`);
        break;
      default:
        button.value.classList.add(`py-10p`);
        button.value.classList.add(`px-5`);
        break;
    }
    if (cursor.value) button.value.classList.add(`cursor-${cursor.value}`);
  }
});

function onClick() {
  emit("r:click");
  if (to.value) {
    router.push(to.value);
  }
}
</script>

<template>
  <button
    @click="onClick"
    :disabled="disabled"
    class="relative text-center text-sm transition duration-300 ease-out rounded-md font-semibold leading-none focus:outline-none"
    :class="[
      {
        'py-10p px-5': !size,
      },
    ]"
    ref="button"
  >
    <span
      class="z-10"
      :class="[!loading ? 'opacity-1' : 'opacity-0']"
    >
      <slot />
    </span>
    <span
      v-if="loading"
      class="flex items-center h-full w-full justify-center left-0 top-0 absolute z-20"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block; shape-rendering: auto"
        width="35px"
        height="35px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.9166666666666666s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(30 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.8333333333333334s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(60 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.75s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.6666666666666666s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(120 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.5833333333333334s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(150 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.5s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.4166666666666667s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(210 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.3333333333333333s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(240 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.25s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.16666666666666666s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(300 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.08333333333333333s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(330 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#fff9fb"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
      </svg>
    </span>
  </button>
</template>
